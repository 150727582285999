import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ConditionRoute from "./Route/ConditionRoute";
import Spinner from "../app/shared/Spinner";

const Dashboard = lazy(() => import("./Pages/dashboard/Dashboard"));
const ListFAQ = lazy(() => import("./Pages/FAQ/ListFAQ"));
const AddFAQ = lazy(() => import("./Pages/FAQ/AddFAQ.js"));
const ChangePassword = lazy(() => import("./user-pages/ChangePassword"));
const Login = lazy(() => import("./user-pages/Login"));
//WriteContracts
const WriteContracts = lazy(() => import("./Pages/Contracts/WriteContracts"));
const ReadContracts = lazy(() => import("./Pages/Contracts/ReadContract"));
//Plan
const AddPlan = lazy(() => import("./Pages/Plan/AddPlan"));
const EditPlan = lazy(() => import("./Pages/Plan/EditPlan"));
const PlanList = lazy(() => import("./Pages/Plan/PlanList"));
//History
const UserHistory = lazy(() => import("./Pages/History/UserHistory.js"));
const BlockUsers = lazy(() => import("./Pages/History/BlockUsers"));
const RankIncomeHistory = lazy(() =>
  import("./Pages/History/RankIncomeHistory")
);
const SingleRankHist = lazy(() => import("./Pages/History/SingleRankHist"));
const RoiIncomeHistory = lazy(() => import("./Pages/History/RoiIncomeHistory"));
const RoiUplinerIncomeHistory = lazy(() =>
  import("./Pages/History/RoiUplinerIncomeHistory")
);
const InvesmentHistory = lazy(() => import("./Pages/History/InvesmentHistory"));
const withdrawHistory = lazy(() => import("./Pages/History/withdrawHistory"));
const DirectIncomeHist = lazy(() => import("./Pages/History/DirectIncomeHist"));
// Settings
const Settings = lazy(() => import("./Pages/Settings/Settings"));
const ListRoyaltySetting = lazy(() =>
  import("./Pages/Settings/ListRoyaltySetting")
);
const AddRoyaltyReward = lazy(() =>
  import("./Pages/Settings/AddRoyaltyReward")
);
const AddRankSetting = lazy(() => import("./Pages/Settings/AddRankSetting"));
const ListRankSetting = lazy(() => import("./Pages/Settings/ListRankSetting"));

export default function AppRoutes() {
  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        {/* Dashboard */}

        <ConditionRoute
          path="/dashboard"
          component={Dashboard}
          type={"private"}
        />

        {/* FAQ */}

        {/* <ConditionRoute
          path="/FAQ/ListFAQ"
          component={ListFAQ}
          type={"private"}
        />

        <ConditionRoute
          path="/FAQ/AddFAQ"
          component={AddFAQ}
          type={"private"}
        /> */}

        {/* History */}

        <ConditionRoute
          path="/History/UserHistory"
          component={UserHistory}
          type={"private"}
        />

        <ConditionRoute
          path="/History/BlockUsers"
          component={BlockUsers}
          type={"private"}
        />

        <ConditionRoute
          path="/History/RankIncomeHistory"
          component={RankIncomeHistory}
          type={"private"}
        />

        <ConditionRoute
          path="/History/SingleRankHist"
          component={SingleRankHist}
          type={"private"}
        />

        <ConditionRoute
          path="/History/RoiIncomeHistory"
          component={RoiIncomeHistory}
          type={"private"}
        />

        <ConditionRoute
          path="/History/RoiUplinerIncomeHistory"
          component={RoiUplinerIncomeHistory}
          type={"private"}
        />

        <ConditionRoute
          path="/History/DirectIncomeHist"
          component={DirectIncomeHist}
          type={"private"}
        />

        <ConditionRoute
          path="/History/withdrawHistory"
          component={withdrawHistory}
          type={"private"}
        />

        <ConditionRoute
          path="/History/InvesmentHistory"
          component={InvesmentHistory}
          type={"private"}
        />
        {/* Plan */}

        <ConditionRoute
          path="/Plan/AddPlan"
          component={AddPlan}
          type={"private"}
        />

        <ConditionRoute
          path="/Plan/EditPlan"
          component={EditPlan}
          type={"private"}
        />

        <ConditionRoute
          path="/Plan/PlanList"
          component={PlanList}
          type={"private"}
        />

        {/* Settings */}

        <ConditionRoute
          path="/Settings/Settings"
          component={Settings}
          type={"private"}
        />

        <ConditionRoute
          path="/Settings/AddRoyaltyReward"
          component={AddRoyaltyReward}
          type={"private"}
        />

        <ConditionRoute
          path="/Settings/ListRoyaltySetting"
          component={ListRoyaltySetting}
          type={"private"}
        />

        <ConditionRoute
          path="/Settings/AddRankSetting"
          component={AddRankSetting}
          type={"private"}
        />

        <ConditionRoute
          path="/Settings/ListRankSetting"
          component={ListRankSetting}
          type={"private"}
        />
        {/* Contracts */}

        {/* <ConditionRoute
          path="/Contract/WriteContracts"
          component={WriteContracts}
          type={"private"}
        />

        <ConditionRoute
          path="/Contract/ReadContracts"
          component={ReadContracts}
          type={"private"}
        /> */}

        {/* Admin */}

        <ConditionRoute
          path="/ChangePassword"
          component={ChangePassword}
          type={"private"}
        />

        <ConditionRoute
          path="/login"
          component={Login}
          type={"auth"}
        />

        <Redirect to="/dashboard" />
      </Switch>
    </Suspense>
  );
}
