let key = {};

let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
};

let Envname = "demo";

if (Envname == "prod") {
  const API_URL = "https://prodapi.golfinos.biz"; //DEMO
  key = {
    baseUrl: `${API_URL}`,
    toasterOption: toasterOption,
    BUSDcontract: "0x55d398326f99059ff775485246999027b3197955",
    Golfinoscontract: "0xa79733cbDe796cf110f9d52B24F26dAdA06f4707",
    admin_address: "0xAFE2935B86173865266d9eE08Cd6a827A00926FC", //0x4c5c6b17843808b1442F8D41aC391396B68a32DD
    ScanUrl: "https://bscscan.com/tx/",
    NetworkId: 56,
    decimals: 1e18
  };
} else if (Envname == "demo") {
  console.log("0x58C94cd8562913362845BE1005e839a48e0fAf30 Deo");
  const API_URL = "https://golfinosapi.wealwin.com"; //DEMO
  key = {
    baseUrl: `${API_URL}`,
    toasterOption: toasterOption,
    BUSDcontract: "0x8CE7720fD183AeC96b676FD8250724b05b0d7a6F",
    Golfinoscontract: "0x4437AD3082c83F245256A3b1777242D307300551",
    admin_address: "0x80Aa595c83430288E3A75F4D03bE15b72385420F",
    ScanUrl: "https://testnet.bscscan.com/tx/",
    NetworkId: 97,
    decimals: 1e18
  };
} else {
  const API_URL = "http://localhost";
  key = {
    baseUrl: `${API_URL}:3040`,
    toasterOption: toasterOption,
    BUSDcontract: "0x8CE7720fD183AeC96b676FD8250724b05b0d7a6F",
    Golfinoscontract: "0x3B28E74214c59752c8B064EA719E7428Ee8e94eE",
    admin_address: "0x80Aa595c83430288E3A75F4D03bE15b72385420F",
    ScanUrl: "https://testnet.bscscan.com/tx/",
    NetworkId: 97,
    decimals: 1e18
  };
}

export default key;
